import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { IVersion } from '@app/shared/models/version.model';
import { environment } from '@env/environment';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-unauth-footer',
    templateUrl: './unauth-footer.component.html',
    styleUrls: ['./unauth-footer.component.scss'],
    standalone: false,
})
export class UnauthFooterComponent implements OnInit {
    @Input() page: string;

    availableLanguages = environment.availableLanguages.filter(
        lang => lang.code === 'de' || lang.code === 'en'
    );
    versionString: string;

    constructor(
        private translate: TranslateService,
        public appConfig: AppConfig,
        private http: HttpClient
    ) {}

    async ngOnInit() {
        try {
            const version = await lastValueFrom(
                this.http.get<IVersion>(
                    `assets/version.json?v=${new Date().getTime()}`
                )
            );
            if (version) {
                let versionString = '';
                if (version.tag) {
                    versionString += `Tag:\n${version.tag}\n`;
                }
                if (version.branch) {
                    versionString += `Branch:\n${version.branch}\n`;
                }
                if (version.commit) {
                    versionString += `Commit ID:\n${version.commit}`;
                }
                this.versionString = versionString;
            }
        } catch (error) {}
    }

    public changeLanguage(code: string) {
        this.translate.use(code);
    }
}
