import { Component } from '@angular/core';

@Component({
    selector: 'app-email-got-verified',
    templateUrl: './email-got-verified.component.html',
    styleUrls: ['./email-got-verified.component.scss'],
    standalone: false,
})
export class EmailGotVerifiedComponent {
    constructor() {}
}
