import {
    AfterViewInit,
    Component,
    ElementRef,
    OnInit,
    ViewChild,
} from '@angular/core';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-selfservice-language-selector',
    templateUrl: './selfservice-language-selector.component.html',
    styleUrls: ['./selfservice-language-selector.component.scss'],
    standalone: false,
})
export class SelfserviceLanguageSelectorComponent
    implements OnInit, AfterViewInit
{
    @ViewChild('languageContainer') languageContainer: ElementRef;
    availableLanguages = environment.availableLanguages;
    currentLanguage: string;
    activeIndex: number = 0;
    showLanguagesEverywhere: boolean = true;
    showLanguages: boolean = true;

    constructor(
        private storageService: StorageService,
        private translateService: TranslateService,
        public location: Location,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.currentLanguage = this.translateService.currentLang;

        const selfServiceLanguagesEverywhereExists =
            this.storageService.itemExists('selfServiceLanguagesEverywhere');
        if (selfServiceLanguagesEverywhereExists) {
            this.showLanguagesEverywhere = this.storageService.getItemAsObject(
                'selfServiceLanguagesEverywhere'
            );
        } else {
            this.showLanguagesEverywhere = false;
        }

        this.updateLanguageVisibility(this.router.url);

        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateLanguageVisibility(event.urlAfterRedirects);
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.storageService.itemExists('language_code')) {
            this.changeLanguage(
                this.storageService.getItemAsObject('language_code')
            );
        }
    }

    /**
     * This function returns an array of the three languages that are displayed on a language selection widget,
     * based on the current active index in the list of available languages. The first element is one position before (in circular order),
     * the second is the current active language, and the third is one position after (also in circular order).
     * This setup allows for seamless navigation through different languages without having to manage array boundaries or special cases.
     */
    displayedLanguages() {
        return [
            this.availableLanguages[
                (this.activeIndex + this.availableLanguages.length - 1) %
                    this.availableLanguages.length
            ],
            this.availableLanguages[this.activeIndex],
            this.availableLanguages[
                (this.activeIndex + 1) % this.availableLanguages.length
            ],
        ];
    }

    /**
     * This function adjusts the scroll position of a language selection widget to move to the previous language in the list by scrolling left.
     * It does this by calling `adjustScrollPosition` with the argument -1, which tells it to move left (to the previous languages).
     */
    movePrev(): void {
        this.adjustScrollPosition(-1);
    }

    /**
     * This function adjusts the scroll position of a language selection widget to move to the next language in the list by scrolling right.
     * It does this by calling `adjustScrollPosition` with the argument 1, which tells it to move right (to the next languages).
     */
    moveNext(): void {
        this.adjustScrollPosition(1);
    }

    /**
     * This function adjusts the scroll position of a language selection widget by a certain amount in a given direction.
     * The amount is defined as `scrollAmount` and the direction can be either 1 for right (next) or -1 for left (previous).
     */
    adjustScrollPosition(direction: number): void {
        const container = this.languageContainer.nativeElement;
        const scrollAmount = 150;

        container.scrollLeft += scrollAmount * direction;
    }

    /**
     * This function changes the current language of your application by setting a new language code in both the translation service and local storage.
     * @param {string} code - The two-letter ISO language code (e.g., 'en' for English, 'es' for Spanish) to change to.
     */
    changeLanguage(code: string) {
        this.translateService.use(code);
        this.currentLanguage = code;
    }

    /**
     * This function updates the visibility of language selection element based on the current URL
     * and a flag indicating whether languages should be shown everywhere or only at '/selfservice'.
     * @param {string} currentUrl - The current URL path.
     */
    private updateLanguageVisibility(currentUrl: string): void {
        const path = currentUrl.split('?')[0];

        if (!this.showLanguagesEverywhere && path !== '/selfservice') {
            this.showLanguages = false;
        } else {
            this.showLanguages = true;
        }
    }
}
