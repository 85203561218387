import { Location } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig } from '@app/app.config';
import { SessionService } from '@app/core/services/client-services/session-service/session.service';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { CurrentSiteRole } from '@app/shared/models/authentication/session';
import { environment } from '@env/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
    LoginOrganizationSite,
    LoginResponse,
    Organization,
    UserRoles,
} from 'api/models';

@UntilDestroy({
    checkProperties: true,
})
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    standalone: false,
})
export class HeaderComponent implements OnInit, AfterViewInit {
    isLoggedIn: boolean = false;
    userSession: LoginResponse | null;
    isExternal: boolean = false;
    isLegal: boolean = false;
    showHeader: boolean = false;
    organization: Organization;
    currentOrganization: LoginOrganizationSite;
    availableLanguages = environment.availableLanguages.filter(
        lang => lang.code === 'de' || lang.code === 'en'
    );
    currentLanguage: string;
    currentSiteRole: CurrentSiteRole;
    organisationName: string;

    constructor(
        private router: Router,
        public sessionService: SessionService,
        private storageService: StorageService,
        private translateService: TranslateService,
        public appConfig: AppConfig,
        public location: Location
    ) {}

    ngOnInit(): void {
        this.currentLanguage = this.translateService.currentLang;

        if (this.storageService.itemExists('organisationName')) {
            this.organisationName =
                this.storageService.getItemAsObject('organisationName');
        }

        this.sessionService.userSession$
            .pipe(untilDestroyed(this))
            .subscribe(session => {
                // console.log('user:', session);
                this.isLoggedIn = session ? true : false;
                this.userSession = session ? session : null;
                if (session?.organizations[0]?.name) {
                    this.organisationName = session.organizations[0].name;
                }
            });

        this.sessionService.currentOrganizationSite$
            .pipe(untilDestroyed(this))
            .subscribe(currentOrganization => {
                this.currentOrganization = currentOrganization;
            });

        this.sessionService.currentSiteRole$
            .pipe(untilDestroyed(this))
            .subscribe((data: CurrentSiteRole) => {
                this.currentSiteRole = data;
            });

        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isExternal =
                    event.url.includes('/external') &&
                    !event.url.includes('/admin');
                this.isLegal = event.url.includes('/legal');
            }
        });
    }

    ngAfterViewInit(): void {
        if (this.storageService.itemExists('language_code')) {
            this.changeLanguage(
                this.storageService.getItemAsObject('language_code')
            );
        }
    }

    changeLanguage(code: string) {
        this.translateService.use(code);
        this.currentLanguage = code;
        this.storageService.setItem('language_code', code);
    }

    canSelectAdminPanel(): boolean {
        if (this.isExternal) {
            return false;
        }
        const permissions = this.sessionService.getSession()?.permissions;
        const roles = this.sessionService.getSession()?.roles;

        if (
            permissions?.service_provider_organizations_create ||
            permissions?.service_provider_organizations_read ||
            permissions?.service_provider_organizations_update ||
            permissions?.service_provider_organizations_delete ||
            permissions?.administration_users_read ||
            roles?.includes(UserRoles.SiteAdmin) ||
            roles?.includes(UserRoles.OrganizationAdmin)
        ) {
            return true;
        } else {
            return false;
        }
    }
}
