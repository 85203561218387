import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-twofa-verification-dialog',
    templateUrl: './twofa-verification-dialog.component.html',
    styleUrls: ['./twofa-verification-dialog.component.scss'],
    standalone: false,
})
export class TwofaVerificationDialogComponent implements OnDestroy, OnInit {
    @HostListener('document:keydown.enter', ['$event'])
    onKeydownHandler(event: KeyboardEvent): void {
        event.preventDefault();
        this.submitTwofaCode();
    }

    @HostListener('document:keydown.escape', ['$event']) onEscapeHandler(
        event: KeyboardEvent
    ) {
        event.preventDefault();
        // this.dialogRef.close();
    }

    wrongCodeError = false;
    showSuccess = false;
    codeLength = 6;

    timeLeft: number = 60 * 15;
    interval: any;

    oneTimeCodesForm = new UntypedFormGroup({
        code: new UntypedFormControl('', [Validators.required]),
    });

    constructor(
        private dialogRef: MatDialogRef<TwofaVerificationDialogComponent>
    ) {}

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                this.timeLeft = 60 * 15;
                this.dialogRef.close();
            }
        }, 1000);
    }

    ngOnInit(): void {
        this.startTimer();
    }

    ngOnDestroy(): void {
        clearInterval(this.interval);
    }

    async submitTwofaCode() {
        if (!this.oneTimeCodesForm.valid) {
            return;
        }
        const code = this.oneTimeCodesForm.value['code'].trim();
        this.dialogRef.close(code);
    }
}
