import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast.service';
import { confirmedEqualValidator } from '@app/shared/form-validators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { PostPublicCompleteResetUserPassword } from 'api/models';
import { PublicService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-set-new-password',
    templateUrl: './set-new-password.component.html',
    styleUrls: ['./set-new-password.component.scss'],
    standalone: false,
})
export class SetNewPasswordComponent implements OnInit {
    setPasswordForm: UntypedFormGroup;
    token: string;
    loading: boolean;
    hidePassword: boolean = true;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        private toastr: ToastService,
        private publicService: PublicService
    ) {}

    ngOnInit(): void {
        this.token = this.activatedRoute.snapshot.queryParams.token;

        this.setPasswordForm = this.formBuilder.group(
            {
                newPassword: ['', [Validators.required]],
                confirmNewPassword: [
                    '',
                    [Validators.required, Validators.minLength(10)],
                ],
            },
            {
                validators: [
                    confirmedEqualValidator(
                        'newPassword',
                        'confirmNewPassword'
                    ),
                ],
            }
        );
    }

    async onSetNewPassword() {
        const payload: PostPublicCompleteResetUserPassword = {
            token: this.token,
            password: this.setPasswordForm.value['newPassword'],
        };
        this.loading = true;
        try {
            await lastValueFrom(
                this.publicService.postPublicCompleteResetUserPassword({
                    body: payload,
                })
            );
            this.toastr.success(
                this.translateService.instant('SET-NEW-PASSWORD.CHANGE-SUCCESS')
            );
            this.router.navigate(['/login']);
        } catch (error) {
            this.toastr.error(
                this.translateService.instant('SET-NEW-PASSWORD.CHANGE-FAILED')
            );
        } finally {
            // Timeout for better UX
            setTimeout(() => {
                this.loading = false;
            }, 300);
        }
    }

    //  getter for  access to form field
    get f() {
        return this.setPasswordForm.controls;
    }
}
