import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SetNewPasswordComponent } from '@app/core/components/set-new-password/set-new-password.component';
import { RoleGuard } from '@app/core/guards/role.guard';
import { ForgotPasswordComponent } from '@app/core/pages/forgot-password/forgot-password.component';
import { LegalComponent } from '@app/core/pages/legal/legal.component';
import { PasswordResetComponent } from '@app/core/pages/password-reset/password-reset.component';
import { Role } from '@app/shared/models/authentication/role';
import { AuthGuard } from './core/guards/auth.guard';
import { MaintenanceGuard } from './core/guards/maintenance.guard';
import { RedirectGuard } from './core/guards/redirect.guard';
import { EmailGotVerifiedComponent } from './core/pages/email-got-verified/email-got-verified.component';
import { LandingComponent } from './core/pages/landing/landing.component';
import { SelfserviceMainComponent } from './external/pages/selfservice/selfservice-main.component';
import { MaintenanceComponent } from './others/pages/maintenance/maintenance.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { RoutingEndpoints } from './shared/models/routing/routing-endpoints';

const routes: Routes = [
    {
        path: RoutingEndpoints.selfservice,
        component: SelfserviceMainComponent,
        children: [
            {
                path: '',
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-checkin-select/selfservice-checkin-select.module'
                    ).then(m => m.SelfserviceCheckinSelectModule),
            },
            {
                path: RoutingEndpoints.selfservice_qrscan,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-qrcode-scanner/selfservice-qrcode-scanner.module'
                    ).then(m => m.SelfserviceQrcodeScannerModule),
            },
            {
                path: RoutingEndpoints.selfservice_handscan,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-hand-scanner/selfservice-hand-scanner.module'
                    ).then(m => m.SelfserviceHandScannerModule),
            },
            {
                path: RoutingEndpoints.selfservice_bundle,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-bundle-number/selfservice-bundle-number.module'
                    ).then(m => m.SelfserviceBundleNumberModule),
            },
            {
                path: RoutingEndpoints.selfservice_data,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-driver-data/selfservice-driver-data.module'
                    ).then(m => m.SelfserviceDriverDataModule),
            },
            {
                path: RoutingEndpoints.selfservice_control,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-checkin-control/selfservice-checkin-control.module'
                    ).then(m => m.SelfserviceCheckinControlModule),
            },
            {
                path: RoutingEndpoints.selfservice_pending,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-pending/selfservice-pending.module'
                    ).then(m => m.SelfservicePendingModule),
            },
            {
                path: RoutingEndpoints.selfservice_configuration,
                loadChildren: () =>
                    import(
                        './external/pages/selfservice/pages/selfservice-configuration/selfservice-configuration.module'
                    ).then(m => m.SelfserviceConfigurationModule),
            },
        ],
    },
    {
        path: 'maintenance',
        component: MaintenanceComponent,
        loadChildren: () =>
            import('./others/pages/maintenance/maintenance.module').then(
                m => m.MaintenanceComponentModule
            ),
    },
    {
        path: 'datenschutz',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
            externalUrl: 'https://www.gs1-germany.de/datenschutz/',
        },
    },
    {
        path: 'privacy',
        canActivate: [RedirectGuard],
        component: RedirectGuard,
        data: {
            externalUrl: 'https://www.gs1-germany.de/datenschutz/',
        },
    },
    {
        path: 'login',
        component: LandingComponent,
        canActivate: [MaintenanceGuard],
    },
    /*{
        path: RoutingEndpoints.login,
        component: LoginComponent,
        canActivate: [MaintenanceGuard],
    },*/
    {
        path: RoutingEndpoints.forgot_password,
        component: ForgotPasswordComponent,
        canActivate: [MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.reset_user_password,
        component: SetNewPasswordComponent,
        canActivate: [MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.check_email,
        component: PasswordResetComponent,
        canActivate: [MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.email_got_verified,
        component: EmailGotVerifiedComponent,
        canActivate: [MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.settings,
        loadChildren: () =>
            import('./user/user.module').then(m => m.UserModule),
        canActivate: [MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.admin,
        loadChildren: () =>
            import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard, MaintenanceGuard],
    },
    {
        path: RoutingEndpoints.consignor,
        loadChildren: () =>
            import('./consignor/consignor.module').then(m => m.ConsignorModule),
        canActivate: [AuthGuard, RoleGuard, MaintenanceGuard],
        data: {
            expectedRoles: [Role.CONSIGNOR, Role.REMOTECONSIGNOR],
        },
    },
    {
        path: RoutingEndpoints.external,
        loadChildren: () =>
            import('./external/external.module').then(m => m.ExternalModule),
        canActivate: [MaintenanceGuard],
        data: {
            expectedRoles: [Role.FRACHTFUEHRER],
        },
    },
    {
        path: RoutingEndpoints.consignee,
        loadChildren: () =>
            import('./consignee/consignee.module').then(m => m.ConsigneeModule),
        canActivate: [AuthGuard, RoleGuard, MaintenanceGuard],
        data: {
            expectedRoles: [Role.CONSIGNEE, Role.REMOTECONSIGNEE],
        },
    },
    {
        path: RoutingEndpoints.carrier,
        loadChildren: () =>
            import('./carrier/carrier.module').then(m => m.CarrierModule),
        canActivate: [AuthGuard, RoleGuard, MaintenanceGuard],
        data: {
            expectedRoles: [Role.CARRIER, Role.REMOTECARRIER],
        },
    },
    {
        path: '',
        redirectTo: RoutingEndpoints.login,
        pathMatch: 'full',
    },
    {
        path: '**',
        pathMatch: 'full',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
