import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { EventsService } from '@app/core/services/event.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { StorageService } from '@app/core/services/client-services/storage-service/storage.service';
import { RoutingEndpoints } from '@app/shared/models/routing/routing-endpoints';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-selfservice-timer',
    templateUrl: './selfservice-timer.component.html',
    styleUrls: ['./selfservice-timer.component.scss'],
    standalone: false,
})
export class SelfserviceTimerComponent implements OnInit, OnDestroy {
    @Input() restartIdle: boolean = false;
    @Output() triggerTimeout: EventEmitter<boolean> = new EventEmitter();

    @HostListener('window:mousedown')
    @HostListener('window:mousemove')
    checkUserActivity() {
        if (this.isTimerAllowed()) {
            clearTimeout(this.timeoutId);
            this.resetTimer();
        }
    }

    timeoutId: ReturnType<typeof setTimeout>;
    userInactive$: Subject<boolean> = new Subject();
    inactivityTimeout: number = 60; // Default value
    remainingTime: number;
    isVisible: boolean = false;
    excludedRoutes = ['/selfservice', '/selfservice/configuration'];

    constructor(
        private eventsService: EventsService,
        private router: Router,
        private storageService: StorageService
    ) {}

    ngOnInit(): void {
        this.setupTimer();
        this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.adjustTimeoutBasedOnRoute();
                this.isVisible = this.isTimerAllowed();
                if (this.isVisible) {
                    this.resetTimer();
                }
            }
        });

        this.adjustTimeoutBasedOnRoute();

        this.userInactive$.pipe(untilDestroyed(this)).subscribe(_ => {
            this.triggerTimeout.emit(false);
        });

        this.eventsService
            .subscribe('user-idle:confirmed')
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.isVisible = false;
                this.triggerTimeout.emit(true);
                clearTimeout(this.timeoutId);
            });
    }

    ngOnDestroy(): void {
        clearTimeout(this.timeoutId);
    }

    /**
     * This function sets up the timer by adjusting its timeout based on the current route,
     * checking if the timer is allowed for display, and resetting it if necessary.
     */
    setupTimer(): void {
        this.adjustTimeoutBasedOnRoute();
        this.isVisible = this.isTimerAllowed();
        if (this.isVisible) {
            this.resetTimer();
        }
    }

    /**
     * This function adjusts the inactivity timeout based on the current route.
     * It does so by splitting the URL, mapping it to a storage key using `mapRouteToStorageKey()`,
     * and then retrieving its value from local storage if it exists or setting default values for various routes.
     */
    adjustTimeoutBasedOnRoute() {
        const currentRoute = this.router.url.split('?')[0].split('#')[0];
        const timeoutKey = this.mapRouteToStorageKey(currentRoute);
        if (this.storageService.itemExists(timeoutKey)) {
            this.inactivityTimeout =
                this.storageService.getItemAsObject(timeoutKey) ||
                this.inactivityTimeout;
        } else {
            if (currentRoute.endsWith(RoutingEndpoints.selfservice_pending)) {
                this.inactivityTimeout = 10;
            } else {
                this.inactivityTimeout = 60;
            }
        }
        this.remainingTime = this.inactivityTimeout;
    }

    /**
     * This function resets the inactivity timer by clearing any existing timeout,
     * setting `remainingTime` to `inactivityTimeout`, and then initiating a new countdown with `updateCountdown()`.
     */
    resetTimer() {
        if (!this.isTimerAllowed()) {
            return;
        }
        clearTimeout(this.timeoutId);
        this.remainingTime = this.inactivityTimeout;
        this.updateCountdown();
    }

    /**
     * This function updates the countdown by decrementing `remainingTime` every second until it
     * reaches zero, at which point the timer is hidden and a user inactive event is emitted.
     * After that, it navigates to the start page using `navigateToStart()`.
     */
    updateCountdown() {
        if (!this.isTimerAllowed()) {
            return;
        }
        this.timeoutId = setTimeout(() => {
            if (this.remainingTime > 0) {
                this.remainingTime--;
                this.updateCountdown();
            } else {
                this.isVisible = false;
                this.userInactive$.next(true);
                this.navigateToStart();
            }
        }, 1000);
    }

    /**
     * This function checks if the inactivity timer is allowed based on the current route by comparing
     * it to a list of excluded routes using `Array.prototype.includes()`.
     * It also ensures that any trailing slash in the current route is removed before checking
     * for its inclusion in the list of excluded routes.
     */
    isTimerAllowed(): boolean {
        let currentRoute = this.router.url.split('?')[0].split('#')[0];
        currentRoute = currentRoute.endsWith('/')
            ? currentRoute.slice(0, -1)
            : currentRoute;

        return !this.excludedRoutes.includes(currentRoute);
    }

    /**
     * This function maps a given route to a corresponding storage key by removing the base route and replacing any slashes with underscores.
     * It then uses a switch statement to map certain specific routes to predefined constants in `RoutingEndpoints`,
     * and returns ‘selfservice_default’ for all other cases or if no match is found.
     * @param {string} route - The URL path of the route to be mapped.
     */
    mapRouteToStorageKey(route: string): string {
        const baseRoute = '/selfservice/';
        if (route.startsWith(baseRoute)) {
            const routeKey = route
                .substring(baseRoute.length)
                .replace('/', '_');
            switch (routeKey) {
                case 'qrscan':
                    return RoutingEndpoints.selfservice_qrscan;
                case 'handscan':
                    return RoutingEndpoints.selfservice_handscan;
                case 'bundle':
                    return RoutingEndpoints.selfservice_bundle;
                case 'data':
                    return RoutingEndpoints.selfservice_data;
                case 'control':
                    return RoutingEndpoints.selfservice_control;
                case 'pending':
                    return RoutingEndpoints.selfservice_pending;
                case 'configuration':
                    return RoutingEndpoints.selfservice_configuration;
                default:
                    return 'selfservice_default';
            }
        }
        return 'null';
    }

    navigateToStart(): void {
        this.router.navigate(['/selfservice']);
    }
}
