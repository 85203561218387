import { Component, OnInit } from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'api/services';
import { lastValueFrom } from 'rxjs';

@UntilDestroy({ checkProperties: true })
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    standalone: false,
})
export class ForgotPasswordComponent implements OnInit {
    passwordResetForm: UntypedFormGroup;
    loading: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private usersService: UsersService,
        private toastr: ToastService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.passwordResetForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
        });
    }

    //  getter to access form field
    get f() {
        return this.passwordResetForm.controls;
    }

    /**
     * sends user email/username to API, which triggers an email with a password reset link
     */
    async onResetPassword() {
        this.loading = true;
        try {
            await lastValueFrom(
                this.usersService.putPublicResetUserPassword({
                    body: {
                        emailAddress: this.passwordResetForm.value['email'],
                    },
                })
            );
        } catch (error) {
            this.toastr.error(this.translateService.instant('GENERAL-ERROR'));
        } finally {
            // Timeout for better UX
            setTimeout(() => {
                this.loading = false;
            }, 300);
            this.router.navigate(['/check-email']);
        }
    }
}
