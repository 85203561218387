import { Component } from '@angular/core';

@Component({
    selector: 'app-left-splitview',
    templateUrl: './left-splitview.component.html',
    styleUrls: ['./left-splitview.component.scss'],
    standalone: false,
})
export class LeftSplitviewComponent {
    constructor() {}
}
